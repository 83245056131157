import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor() {}

  public printVersion(): void {
    console.log(
      '%c Version: 1.1.0.211',
      'color:#3369ff; font-weight:bolder; font-size:14px'
    );
  }

  public printObject(title: string, obj: any): void {
    console.log(
      `%c *********** ${title.toUpperCase()} ***********\n`,
      'color:#7e0404; margin-top: 8px; font-style:italic; font-weight:bold',
      typeof obj !== 'undefined' && obj ? obj : 'NULL Or Empty Data',
      `\n*************************************************`
    );
  }
}
